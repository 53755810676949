<template>
  <v-container class="my-4">
    <AppBar ref="appBar" :items="itemsNavegacao" />
    <div v-if="carregandoCliente && !erroCarregarCliente" class="ml-carregando">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-alert
      v-if="erroCarregarCliente && !carregandoCliente"
      type="error"
      prominent
      text
    >
      <v-row align="center">
        <v-col class="grow">
          Não foi possível carregar as informações do cliente.
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" @click="carregaCliente($route.params.id)">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <div
      style="width: 1000px !important; background-color: white !important"
      class="mx-auto elevation-6 rounded"
      v-if="cliente"
    >
      <div class="mx-4 pt-7 pb-5">
        <div class="d-flex">
          <div>
            <p
              class="text-h5 titulo mb-0 font-weight-bold"
              @click="abrirDialogJson(cliente, 'Debug Cliente')"
            >
              {{ cliente.nome }}
            </p>
            <p class="text-subtitle-2 mb-0 font-weight-regular">
              {{ cliente.documento | VMask("###.###.###-##") }}
            </p>
          </div>
          <v-spacer></v-spacer>
          <div>
            <v-btn
              text
              color="primary"
              class="mr-1"
              small
              @click="abrirDialogJson(cliente.metadados, 'Metadados')"
            >
              metadados
            </v-btn>
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-1 font-weight-medium"
                small
                :class="cliente.perfil_cliente"
                v-bind="attrs"
                v-on="on"
              >
                {{ cliente.perfil_cliente || "Sem perfil" | perfilCliente }}
              </v-chip>
            </template>
            <span>Perfil cliente</span>
          </v-tooltip>
          <v-menu>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" style="margin-top: -5px !important">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                :to="{
                  name: 'Notificacoes',
                  query: {
                    cliente_id: cliente.id,
                    STATUS_NOTIFICACAO: 'ENVIADO',
                  },
                }"
              >
                <v-list-item-title>
                  Histórico de Notificações
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-if="cliente.data_nascimento">
          <p
            style="line-height: 12px !important"
            class="pt-6 mb-0 font-weight-medium"
          >
            {{ cliente.data_nascimento | moment("L") }}
          </p>
          <p class="text-subtitle-2 mb-0 font-weight-regular">
            Data de nascimento
          </p>
        </div>
        <div v-if="cliente.endereco_rua">
          <p
            style="line-height: 12px !important"
            class="pt-6 mb-0 font-weight-medium"
          >
            {{ cliente.endereco_rua }}, {{ cliente.endereco_numero }}
          </p>
          <p class="text-subtitle-2 mb-0 font-weight-regular">
            {{ cliente.endereco_cidade }}, {{ cliente.endereco_uf }} -
            {{ cliente.endereco_cep | VMask("#####-###") }}
          </p>
        </div>
      </div>

      <v-divider></v-divider>

      <p class="text-h6 ml-4 my-6">
        Contratos {{ empresaSelecionada.nome }} ({{ cliente.contratos.length }})
      </p>

      <v-list
        class="py-0"
        v-for="(contrato, index) in cliente.contratos"
        :key="index"
      >
        <v-list-item
          class="my-2"
          :to="{ name: 'VerContrato', params: { id: contrato.id } }"
        >
          <v-list-item-content
            class="pt-0 pb-0"
            style="
              display: unset !important;
              flex: none !important;
              width: 300px;
            "
          >
            <v-list-item-title>
              {{ contrato.tipo }} -
              {{ contrato.ref_externa }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Importado em {{ contrato.criado_em | dataLocal("L [às] LT") }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-content
            class="ml-4 body-3"
            :style="
              !contrato.notificavel && contrato.qtde_parcelas
                ? 'min-width: 295px'
                : 'min-width: 350px'
            "
          >
            <p
              style="
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              "
            >
              {{ contrato.composicao }}
            </p>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <v-list-item-content style="flex: none" class="mr-1">
            <v-chip
              small
              style="
                width: 110px;
                border: 1px solid black;
                background-color: white;
              "
              class="justify-center font-weight-medium"
              v-if="contrato.qtde_parcelas"
            >
              {{ contrato.parcelasPagas }} de {{ contrato.qtde_parcelas }} pagas
            </v-chip>
          </v-list-item-content>
          <v-list-item-content style="flex: none" class="mr-1">
            <v-chip
              v-if="!contrato.notificavel"
              small
              style="max-width: 110px"
              color="red"
              text-color="white"
              class="justify-center font-weight-medium"
            >
              Não notificável
            </v-chip>
          </v-list-item-content>
          <v-list-item-content style="flex: none">
            <v-chip
              small
              style="width: 100px"
              class="justify-center status-contrato font-weight-medium"
              :class="contrato.status"
            >
              {{ contrato.status | statusContrato }}
            </v-chip>
          </v-list-item-content>
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click.prevent class="ml-2" v-bind="attrs" v-on="on" icon
                ><v-icon>mdi-dots-vertical</v-icon></v-btn
              >
            </template>
            <v-list>
              <v-list-item @click="irParaNotificacoes(contrato)">
                Notificações
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
        <v-divider v-if="index < cliente.contratos.length - 1"></v-divider>
      </v-list>

      <v-divider></v-divider>
      <div class="d-flex pt-2">
        <p class="text-h6 ml-4 my-4 mr-3">Canais ({{ canais.length }})</p>
        <v-select
          v-model="filtroSelecionado"
          :items="filtros"
          attach
          text
          label="Filtros"
          multiple
          style="max-width: 490px !important"
          class="ml-2"
          no-data-text="Nenhum filtro cadastrado"
          v-if="!carregandoCanais && !erroCarregarCanais"
          @change="filtrarCanais()"
        >
          <template v-slot:selection="{ item, index }">
            <span v-if="index < 4">{{ `${item.text}` }} &nbsp; </span>
            <span v-if="index === 4" class="grey--text text-caption">
              (+{{ filtroSelecionado.length - 4 }}
              {{
                filtroSelecionado.length == 5 ? "selecionado" : "selecionados"
              }})
            </span>
          </template>
        </v-select>
      </div>

      <div v-if="carregandoCanais && !erroCarregarCanais" class="ml-carregando">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div
        class="msg-erro d-flex flex-column"
        v-if="!carregandoCanais && erroCarregarCanais"
      >
        <v-alert type="error" class="alerta-erro mx-auto mb-1">
          Ocorreu um erro ao carregar os canais.
        </v-alert>
        <v-btn
          class="tentar-novamente mx-auto"
          color="primary"
          @click="recarregarCanais()"
        >
          Tente novamente
        </v-btn>
      </div>
      <div
        v-if="
          filtroSelecionado.length == 0 &&
          !carregandoCanais &&
          !erroCarregarCanais
        "
        class="pa-8 text-center text--secondary"
      >
        Nenhum filtro selecionado
      </div>
      <div
        v-if="
          canaisFiltrados.length == 0 &&
          filtroSelecionado.length > 0 &&
          !carregandoCanais &&
          !erroCarregarCanais
        "
        class="pa-8 text-center text--secondary"
      >
        Nenhum canal encontrado
      </div>
      <v-list
        class="py-0"
        v-for="(canal, index) in canaisFiltrados"
        :key="'A' + index"
      >
        <v-list-item
          class="my-2"
          style="background-color: transparent !important"
          @mouseover="selecionaItem(canal)"
          @mouseleave="desselecionarItem()"
        >
          <v-list-item-icon class="mr-4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">
                  {{ verificaIcone(canal) }}
                </v-icon>
              </template>
              <span>{{ retornaTextoTooltip(canal) }}</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content
            class="pt-0 pb-0"
            :class="{ 'coluna-canal': canal?.ranking }"
          >
            <v-list-item-title>{{ verificaTexto(canal) }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-content v-if="canal?.ranking">
            <span class="coluna-ranking"
              >{{ canal.ranking }} <span class="caption">Ranking</span>
            </span>
          </v-list-item-content>
          <v-list-item-content
            style="flex: none"
            class="mr-1"
            v-if="canal.blacklist"
          >
            <v-chip
              small
              style="width: 80px"
              color="black"
              dark
              class="justify-center font-weight-medium"
            >
              Blacklist
            </v-chip>
          </v-list-item-content>
          <v-list-item-content
            style="flex: none"
            class="mr-1"
            v-if="canal.tipo == 'WHATSAPP' && canal.criado_em"
          >
            <v-chip
              small
              style="border: 1px solid black; background-color: white"
              class="justify-center font-weight-medium"
            >
              Opt-in {{ calculaDias(canal.criado_em) }}
            </v-chip>
          </v-list-item-content>
          <v-list-item-content style="flex: none" class="mr-1">
            <v-chip
              small
              style="width: 110px"
              class="justify-center status-canal font-weight-medium"
              :class="canal.status"
            >
              {{ canal.status | statusCanais }}
            </v-chip>
          </v-list-item-content>
          <v-list-item-action class="ma-0 menu-contato">
            <v-menu v-if="canal === itemSelecionado" v-model="menuAberto">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" class="ma-0">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="abrirDialogJson(canal, 'Debug Contato')">
                  <v-list-item-title> Detalhes </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="invalidaContato(index, canal)"
                  v-if="canal.tipo != 'PUSH' && canal.status == 'ATIVO'"
                >
                  <v-list-item-title> Invalidar </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="adicionaContatoBlacklist(index, canal)"
                  v-if="canal.tipo != 'PUSH' && !canal.blacklist"
                >
                  <v-list-item-title> Blacklist </v-list-item-title>
                </v-list-item>
                <v-list-item
                  @click="removerContatoBlacklist(index, canal)"
                  v-if="canal.blacklist"
                >
                  <v-list-item-title> Remover da Blacklist </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
        <v-divider v-if="index < canaisFiltrados.length - 1"></v-divider>
      </v-list>
    </div>
    <DialogJson ref="dialogJson" />
    <DialogCriacaoContato
      ref="dialogCriacaoContato"
      @adicionaContato="adicionaContato"
      :clienteId="this.$route.params.id"
    />

    <FloatingMultipleBtn v-if="empresaSelecionada.fake">
      <template v-slot:content>
        <v-chip
          class="text-subtitle-2 white--text blue darken-2 ml-1"
          @click="abrirDialogContato('EMAIL')"
        >
          E-mail
        </v-chip>
        <v-chip
          class="text-subtitle-2 white--text red darken-2 ml-1"
          @click="abrirDialogContato('SMS')"
        >
          Sms
        </v-chip>
        <v-chip
          class="text-subtitle-2 white--text purple darken-2 ml-1"
          @click="abrirDialogContato('LIGACAO_CELULAR')"
        >
          Ligação Celular
        </v-chip>
        <v-chip
          class="text-subtitle-2 white--text yellow darken-2 ml-1"
          @click="abrirDialogContato('LIGACAO_FIXO')"
        >
          Ligação Fixo
        </v-chip>
        <v-chip
          class="text-subtitle-2 white--text orange darken-2"
          @click="abrirDialogContato('WHATSAPP')"
        >
          WhatsApp
        </v-chip>
      </template>
    </FloatingMultipleBtn>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DialogEditContato from "../components/DIalogEditContato.vue";
import AppBar from "../components/AppBar.vue";
import Vue from "vue";
import parcelaService from "@/services/parcela";
import moment from "moment";
import DialogJson from "../components/DialogJson.vue";
import DialogCriacaoContato from "../components/DialogCriacaoContato.vue";
import blacklistService from "@/services/blacklist";
import FloatingMultipleBtn from "../../../components/comum/FloatingMultipleBtn.vue";

export default {
  components: {
    DialogEditContato,
    AppBar,
    DialogJson,
    DialogCriacaoContato,
    FloatingMultipleBtn,
  },
  async created() {
    await this.carregaCliente();
    await this.getCanais();
    if (!this.cliente) return;
    this.itemsNavegacao.push({
      text: this.cliente.nome,
      subtext: Vue.filter("documentoCliente")(this.cliente.documento),
      disabled: true,
    });
    this.quantidadeCanaisStatus();
    this.quantidadeCanaisTipo();

    this.filtrarCanais();
  },

  watch: {
    empresaSelecionada: {
      handler() {
        this.$router.push({ name: "Contratos" });
      },
    },
    canais: {
      handler() {
        this.filtros = [];
        this.quantidadeCanaisStatus();
        this.quantidadeCanaisTipo();
      },
    },
  },

  data() {
    return {
      itemsNavegacao: [],
      filtros: [],
      filtroSelecionado: ["ATIVO"],
      cliente: null,
      canais: [],
      canaisFiltrados: [],
      floatingBtn: false,
      itemSelecionado: false,
      menuAberto: false,
    };
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("parcela", {
      parcelas: "parcelas",
      carregandoParcelas: "carregandoParcelas",
      erroParcelas: "erroParcelas",
    }),
    ...mapGetters("contrato", {
      contrato: "contrato",
      carregandoContrato: "carregandoContrato",
      erroContrato: "erroContrato",
    }),
    ...mapGetters("contato", {
      sms: "sms",
      emails: "emails",
      ligacao: "ligacao",
      whatsapp: "whatsapp",
      push: "push",
      carregandoCanais: "carregandoCanais",
      erroCarregarCanais: "erroCarregarCanais",
    }),
    ...mapGetters("cliente", {
      carregandoCliente: "carregandoCliente",
      erroCarregarCliente: "erroCarregarCliente",
    }),
  },
  methods: {
    ...mapActions("contato", {
      getSms: "getSms",
      getEmails: "getEmails",
      getLigacao: "getLigacao",
      getWhatsapp: "getWhatsapp",
      getPush: "getPush",
      atualizaEmail: "atualizaEmail",
      atualizaSms: "atualizaSms",
      atualizaWhatsapp: "atualizaWhatsapp",
      atualizaLigacao: "atualizaLigacao",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("cliente", {
      getClienteById: "getClienteById",
    }),
    ...mapActions("parcela", {
      getParcelas: "getParcelas",
    }),
    selecionaItem(item) {
      if (!this.menuAberto) this.itemSelecionado = item;
    },
    desselecionarItem() {
      if (!this.menuAberto) this.itemSelecionado = false;
    },
    abrirDialogContato(tipoContato) {
      this.$refs.dialogCriacaoContato.abrirDialog(tipoContato);
    },
    irParaNotificacoes(contrato) {
      this.$router.push({
        path: `/notificacoes`,
        query: {
          cliente_id: this.cliente.id,
          CONTRATO: contrato.id,
        },
      });
    },
    async recarregarCanais() {
      await this.getCanais();
      this.quantidadeCanaisStatus();
      this.quantidadeCanaisTipo();
      this.filtrarCanais();
    },
    async carregaCliente() {
      this.cliente = await this.getClienteById(this.$route.params.id);
      if (!this.cliente) return;
      this.ordenarContratos();
      this.cliente.contratos.forEach(async (contrato, index) => {
        const data = await this.getParcelasPagas(contrato.id);
        this.cliente.contratos[index].parcelasPagas = data;
      });
    },
    async getCanais() {
      if (!this.cliente?.id) return;
      let params = {
        cliente_id: this.cliente.id,
      };
      await this.getEmails(params);
      await this.getSms(params);
      await this.getLigacao(params);
      await this.getWhatsapp(params);
      await this.getPush(params);
      this.emails.forEach((email) => {
        email["tipo"] = "EMAIL";
      });

      this.sms.forEach((sms) => {
        sms["tipo"] = "SMS";
      });

      this.ligacao.forEach((ligacao) => {
        if (ligacao.tipo == "CELULAR") {
          ligacao["tipo"] = "LIGACAO_CELULAR";
        } else {
          ligacao["tipo"] = "LIGACAO_FIXO";
        }
      });

      this.whatsapp.forEach((whatsapp) => {
        whatsapp["tipo"] = "WHATSAPP";
      });

      this.push.forEach((push) => {
        push["tipo"] = "PUSH";
      });
      this.canais = [
        ...this.whatsapp,
        ...this.push,
        ...this.ligacao,
        ...this.emails,
        ...this.sms,
      ];
      this.ordenarCanais();
    },
    adicionaContato(canal, tipoContato) {
      canal["tipo"] = tipoContato;
      this.canais.push(canal);
      this.quantidadeCanaisStatus();
      this.quantidadeCanaisTipo();
      this.ordenarCanais();
      this.filtrarCanais();
    },
    async invalidaContato(index, canal) {
      let payload = {
        status: "INVALIDO",
        id: canal.id,
      };
      if (canal.tipo == "EMAIL") {
        try {
          const { data } = await this.atualizaEmail(payload);
          this.canaisFiltrados[index].status = "INVALIDO";
          this.ordenarCanais();
          this.filtrarCanais();
          this.showSnackBar({
            text: `Contato invalidado com sucesso!`,
          });
          return data;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao invalidar o contato!`,
          });
        }
      } else if (canal.tipo == "SMS") {
        try {
          const { data } = await this.atualizaSms(payload);
          this.canaisFiltrados[index].status = "INVALIDO";
          this.ordenarCanais();
          this.filtrarCanais();
          this.showSnackBar({
            text: `Contato invalidado com sucesso!`,
          });
          return data;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao invalidar o contato!`,
          });
        }
      } else if (canal.tipo == "WHATSAPP") {
        try {
          const { data } = await this.atualizaWhatsapp(payload);
          this.canaisFiltrados[index].status = "INVALIDO";
          this.ordenarCanais();
          this.filtrarCanais();
          this.showSnackBar({
            text: `Contato invalidado com sucesso!`,
          });
          return data;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao invalidar o contato!`,
          });
        }
      } else {
        try {
          const { data } = await this.atualizaLigacao(payload);
          this.canaisFiltrados[index].status = "INVALIDO";
          this.ordenarCanais();
          this.filtrarCanais();
          this.showSnackBar({
            text: `Contato invalidado com sucesso!`,
          });
          return data;
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: `Erro ao invalidar o contato!`,
          });
        }
      }
    },
    async adicionaContatoBlacklist(index, canal) {
      let payload = {
        blacklist: canal.email ? [canal.email] : [canal.numero],
        origem: "CONSOLE",
        motivo: "BLACKLIST",
      };
      try {
        const { data } = await blacklistService.blacklist(payload);
        this.canaisFiltrados[index].blacklist = true;
        this.showSnackBar({
          text: `Contato adicionado na blacklist!`,
        });
        this.recarregarCanais();
        return data;
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: `Erro ao adicionar contato na blacklist!`,
        });
      }
    },
    async removerContatoBlacklist(index, canal) {
      let id = canal.blacklist.id;
      try {
        const { data } = await blacklistService.removerBlacklist(id);
        this.canaisFiltrados[index].blacklist = false;
        this.showSnackBar({
          text: `Contato removido da blacklist!`,
        });
        this.recarregarCanais();
        return data;
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: `Erro ao remover contato da blacklist!`,
        });
      }
    },
    ordenarCanais() {
      let ordem = {
        EMAIL: 1,
        LIGACAO_CELULAR: 2,
        LIGACAO_FIXO: 3,
        PUSH: 4,
        PUSH_TOKEN: 5,
        RCS: 6,
        SMS: 7,
        WHATSAPP: 8,
      };
      this.canais = this.canais
        .sort(
          ({ ranking: a }, { ranking: b }) =>
            (b === null) - (a === null) || b - a
        )
        .sort((a, b) => ordem[b?.tipo] - ordem[a?.tipo])
        .sort((a, b) => (a.status > b.status ? 1 : -1));
    },
    ordenarContratos() {
      for (var i = 0; i < this.cliente.contratos.length; i++) {
        if (this.cliente.contratos[i].status == "ATIVO") {
          this.cliente.contratos[i]["ordem"] = 1;
        } else if (this.cliente.contratos[i].status == "FINALIZADO") {
          this.cliente.contratos[i]["ordem"] = 2;
        } else if (this.cliente.contratos[i].status == "CANCELADO") {
          this.cliente.contratos[i]["ordem"] = 3;
        }
      }
      this.cliente.contratos.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));
    },
    verificaIcone(canal) {
      if (canal.tipo == "EMAIL") {
        return "mdi-email";
      } else if (canal.tipo == "WHATSAPP") {
        return "mdi-whatsapp";
      } else if (canal.tipo == "SMS") {
        return "mdi-message";
      } else if (canal.tipo == "LIGACAO_CELULAR") {
        return "mdi-cellphone";
      } else if (canal.tipo == "LIGACAO_FIXO") {
        return "mdi-phone";
      } else {
        return "mdi-bell";
      }
    },
    verificaTexto(canal) {
      if (canal.email) {
        return canal.email;
      } else if (canal.numero) {
        return Vue.filter("telefone")(canal.numero);
      } else {
        return "Token: " + canal.token.slice(0, 15) + "...";
      }
    },
    filtrarCanais() {
      this.ordenarCanais();
      if (!this.canais) return;
      this.canaisFiltrados = this.canais.filter((canal) => {
        let filtrosStatus = this.filtroSelecionado.filter((status) => {
          return ["ATIVO", "INVALIDO", "DESCADASTRADO"].includes(status);
        });
        let filtrosTipo = this.filtroSelecionado.filter((filtro) => {
          return [
            "EMAIL",
            "PUSH",
            "SMS",
            "LIGACAO_CELULAR",
            "LIGACAO_FIXO",
            "WHATSAPP",
          ].includes(filtro);
        });

        if (filtrosStatus.length > 0 && filtrosTipo.length == 0) {
          return this.filtroSelecionado.includes(canal.status);
        }
        if (filtrosTipo.length > 0 && filtrosStatus.length == 0) {
          return this.filtroSelecionado.includes(canal.tipo);
        }
        if (filtrosTipo.length > 0 && filtrosStatus.length > 0) {
          return (
            this.filtroSelecionado.includes(canal.tipo) &&
            this.filtroSelecionado.includes(canal.status)
          );
        }
      });
    },
    quantidadeCanaisStatus() {
      if (!this.canais) return;
      let canaisAtivos = 0;
      let canaisInvalidos = 0;
      let canaisDescadastrados = 0;
      this.canais.forEach((canal) => {
        if (canal.status == "ATIVO") {
          canaisAtivos += 1;
        } else if (canal.status == "INVALIDO") {
          canaisInvalidos += 1;
        } else if (canal.status == "DESCADASTRADO") {
          canaisDescadastrados += 1;
        }
      });
      this.filtros.push(
        {
          header: "STATUS",
        },
        {
          text: `Ativo (${canaisAtivos})`,
          value: "ATIVO",
        },
        {
          text: `Inválido (${canaisInvalidos})`,
          value: "INVALIDO",
        },
        {
          text: `Descadastrado (${canaisDescadastrados})`,
          value: "DESCADASTRADO",
        }
      );
    },
    quantidadeCanaisTipo() {
      if (!this.canais) return;
      let canaisEmail = 0;
      let canaisPush = 0;
      let canaisSms = 0;
      let canaisLigacaoCelular = 0;
      let canaisLigacaoFixo = 0;
      let canaisWhatsapp = 0;

      this.canais.forEach((canal) => {
        if (canal.tipo == "EMAIL") {
          canaisEmail += 1;
        } else if (canal.tipo == "PUSH") {
          canaisPush += 1;
        } else if (canal.tipo == "SMS") {
          canaisSms += 1;
        } else if (canal.tipo == "LIGACAO_CELULAR") {
          canaisLigacaoCelular += 1;
        } else if (canal.tipo == "LIGACAO_FIXO") {
          canaisLigacaoFixo += 1;
        } else {
          canaisWhatsapp += 1;
        }
      });

      this.filtros.push(
        {
          header: "TIPO",
        },
        {
          text: `E-mail (${canaisEmail})`,
          value: "EMAIL",
        },
        {
          text: `Push (${canaisPush})`,
          value: "PUSH",
        },
        {
          text: `SMS (${canaisSms})`,
          value: "SMS",
        },
        {
          text: `Ligação Celular (${canaisLigacaoCelular})`,
          value: "LIGACAO_CELULAR",
        },
        {
          text: `Ligação Fixo (${canaisLigacaoFixo})`,
          value: "LIGACAO_FIXO",
        },
        {
          text: `WhatsApp (${canaisWhatsapp})`,
          value: "WHATSAPP",
        }
      );
    },
    async getParcelasPagas(contrato_id) {
      let params = {
        contrato_id: contrato_id,
        status: "PAGA",
        limite: 100,
      };
      const { data } = await parcelaService.get(params);
      return data.num_results;
    },

    calculaDias(optinWhatsapp) {
      if (!optinWhatsapp) return;
      let hoje = moment();
      let diasOptin = hoje.diff(optinWhatsapp);
      if (diasOptin <= 0) {
        return "hoje";
      } else {
        let diasVencimento = moment(optinWhatsapp).from(hoje);
        return diasVencimento;
      }
    },
    retornaTextoTooltip(canal) {
      if (canal.tipo == "SMS") {
        return "SMS";
      } else if (canal.tipo == "EMAIL") {
        return "E-mail";
      } else if (canal.tipo == "LIGACAO_CELULAR") {
        return "Ligação Celular";
      } else if (canal.tipo == "LIGACAO_FIXO") {
        return "Ligação Fixo";
      } else if (canal.tipo == "WHATSAPP") {
        return "WhatsApp";
      } else {
        return "Push";
      }
    },
    abrirDialogJson(dados, titulo) {
      this.$refs.dialogJson.abrirDialog(dados, titulo);
    },
  },
};
</script>

<style lang="scss" scoped>
.novo_cliente {
  background-color: #ffeb3b !important;
  color: black !important;
}

.paga_adiantado {
  background-color: #4caf50 !important;
  color: white !important;
}

.paga_em_dia {
  background-color: #ffab40 !important;
  color: white !important;
}

.paga_atrasado {
  background-color: #ef5350 !important;
  color: white !important;
}

:deep(.theme--light.v-subheader) {
  font-weight: 600;
}

.titulo {
  cursor: pointer;
  line-height: 24px !important;
}

.floating-dial {
  position: fixed;
  bottom: 42px;
  right: 60px;
}

.menu-contato {
  width: 30px !important;
}

.coluna-canal {
  flex: unset;
  width: 422px;
}

.coluna-ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: fit-content;
  color: grey;
}
</style>
