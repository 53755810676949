<template>
  <v-dialog v-model="dialog" :width="950" max-width="100%" persistent>
    <v-form
      @submit.prevent="salvar()"
      ref="form"
      lazy-validation
      class="form-variavel"
    >
      <v-card class="d-flex flex-row">
        <v-card-text>
          <v-card-title class="text-h5 px-0 pt-0" width="100">
            <span class="text-black"
              ><span class="text-capitalize">{{ operacao }}</span>
              variável:</span
            >
            <v-spacer></v-spacer>
            <v-btn
              v-show="mostrarContexto"
              icon
              small
              text
              class="botao-fechar"
              @click="mostrarContexto = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <div class="campos-form pa-0">
            <v-text-field
              v-model="variavel.nome"
              label="Nome"
              outlined
              required
              :rules="[regras.exigido]"
            ></v-text-field>

            <v-textarea
              v-model="variavel.referencia"
              label="Referência"
              ref="campoReferencia"
              rows="3"
              outlined
              required
              :rules="[regras.exigido]"
            ></v-textarea>

            <v-text-field
              v-model="variavel.exemplo"
              label="Exemplo"
              outlined
              required
              :rules="[regras.exigido]"
            ></v-text-field>

            <v-text-field
              v-model="variavel.descricao"
              label="Descrição"
              outlined
              required
              :rules="[regras.exigido]"
            ></v-text-field>

            <v-text-field
              v-model="variavel.tamanho"
              label="Tamanho"
              type="number"
              outlined
              :rules="[regras.numero]"
            ></v-text-field>
          </div>

          <v-card-actions class="pa-0">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              class="text-uppercase"
              @click="fechar()"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="primary"
              class="text-uppercase"
              type="submit"
              :disabled="salvando"
              :loading="salvando"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card-text>

        <div class="contexto-form col-4 pr-4">
          <v-expansion-panels accordion flat>
            <v-expansion-panel height="100vh">
              <v-expansion-panel-header> Contexto </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="item in contextoExtra"
                  :key="item.id"
                  class="pointer extra-pointer"
                  @click="selecionado.push(item.ref)"
                >
                  {{ item.nome }}
                </div>
                <v-treeview
                  :items="contextos"
                  item-key="ref"
                  :active.sync="selecionado"
                  activatable
                  hoverable
                  open-on-click
                >
                </v-treeview>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header> Filtros </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item, index) in filtros"
                  :key="index + item.nome"
                  class="pointer"
                  @click="adicionarFiltro(item.nome)"
                >
                  <v-tooltip bottom color="#494949">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        {{ item.nome }}
                      </div>
                    </template>
                    <span>{{ item.descricao }}</span>
                  </v-tooltip>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      salvando: false,
      mostrarContexto: false,
      operacao: "",
      selecionado: [],
      variavel: {
        id: null,
        nome: null,
        descricao: null,
        exemplo: null,
        tamanho: null,
        tipo_regua: null,
        empresa_id: null,
        ativa: null,
        referencia: "",
      },
      contextos: [
        {
          id: 100,
          name: "Contrato",
          children: [
            { id: 33, ref: "{{ contrato.ref_externa }}", name: "ref_externa" },
            { id: 34, ref: "{{ contrato.tipo }}", name: "tipo" },
            { id: 35, ref: "{{ contrato.categoria }}", name: "categoria" },
            { id: 36, ref: "{{ contrato.canal }}", name: "canal" },
            { id: 37, ref: "{{ contrato.origem }}", name: "origem" },
            { id: 38, ref: "{{ contrato.composicao }}", name: "composicao" },
            {
              id: 39,
              ref: "{{ contrato.vigencia_inicio_em }}",
              name: "vigencia_inicio_em",
            },
            { id: 40, ref: "{{ contrato.status }}", name: "status" },
            {
              id: 41,
              ref: "{{ contrato.finalizado_em }}",
              name: "finalizado_em",
            },
            { id: 42, ref: "{{ contrato.criado_em }}", name: "criado_em" },
            { id: 43, ref: "{{ contrato.dimensao_1 }}", name: "dimensao_1" },
            { id: 44, ref: "{{ contrato.dimensao_2 }}", name: "dimensao_2" },
            { id: 45, ref: "{{ contrato.dimensao_3 }}", name: "dimensao_3" },
            { id: 46, ref: "{{ contrato.dimensao_4 }}", name: "dimensao_4" },
            {
              id: 47,
              ref: "{{ contrato.qtde_parcelas }}",
              name: "qtde_parcelas",
            },
            { id: 48, ref: "{{ contrato.risco }}", name: "risco" },
            {
              id: 49,
              ref: "{{ contrato.importado_em }}",
              name: "importado_em",
            },
            { id: 50, ref: "{{ contrato.notificavel }}", name: "notificavel" },
            {
              id: 51,
              ref: "{{ contrato.proxima_parcela_pendente_id }}",
              name: "proxima_parcela_pendente_id",
            },
            {
              id: 52,
              ref: "{{ contrato.ultima_parcela_paga_id }}",
              name: "ultima_parcela_paga_id",
            },
            {
              id: 53,
              ref: "{{ contrato.notificavel_em }}",
              name: "notificavel_em",
            },
            { id: 54, ref: "{{ contrato.ativo_ate }}", name: "ativo_ate" },
            { id: 55, ref: "{{ contrato.ativo_em }}", name: "ativo_em" },
            { id: 56, ref: "{{ contrato.perfil_empresa }}", name: "perfil_empresa" },
            {
              id: 57,
              ref: "{{ contrato.perfil_contrato }}",
              name: "perfil_contrato",
            },
            {
              id: 59,
              ref: "{{ contrato.motivo_cancelado }}",
              name: "motivo_cancelado",
            },
            { id: 58, ref: "{{ contrato.metadados }}", name: "metadados" },
            {
              id: 60,
              ref: "{{ contrato.data_emissao }}",
              name: "data_emissao",
            },
            { id: 62, ref: "{{ contrato.safra }}", name: "safra" },
            {
              id: 61,
              ref: "{{ contrato.vigencia_termino_em }}",
              name: "vigencia_termino_em",
            },
          ],
        },
        {
          id: 200,
          name: "Parcela",
          children: [
            {
              id: 62,
              ref: "{{ parcela.numero_parcela }}",
              name: "numero_parcela",
            },
            {
              id: 63,
              ref: "{{ parcela.metodo_pagamento }}",
              name: "metodo_pagamento",
            },
            { id: 64, ref: "{{ parcela.status }}", name: "status" },
            { id: 65, ref: "{{ parcela.valor }}", name: "valor" },
            {
              id: 66,
              ref: "{{ parcela.valor_adicional }}",
              name: "valor_adicional",
            },
            { id: 67, ref: "{{ parcela.valor_pago }}", name: "valor_pago" },
            {
              id: 68,
              ref: "{{ parcela.data_vencimento }}",
              name: "data_vencimento",
            },
            {
              id: 69,
              ref: "{{ parcela.data_tolerancia }}",
              name: "data_tolerancia",
            },
            { id: 70, ref: "{{ parcela.pago_em }}", name: "pago_em" },
            { id: 71, ref: "{{ parcela.expirado_em }}", name: "expirado_em" },
            {
              id: 72,
              ref: "{{ parcela.descricao_valor_adicional }}",
              name: "descricao_valor_adicional",
            },
            {
              id: 73,
              ref: "{{ parcela.origem_boleto }}",
              name: "origem_boleto",
            },
            { id: 74, ref: "{{ parcela.url_boleto }}", name: "url_boleto" },
            {
              id: 75,
              ref: "{{ parcela.linha_digitavel }}",
              name: "linha_digitavel",
            },
            {
              id: 76,
              ref: "{{ parcela.boleto_criado_em }}",
              name: "boleto_criado_em",
            },
            {
              id: 78,
              ref: "{{ parcela.descartado_em }}",
              name: "descartado_em",
            },
            {
              id: 77,
              ref: "{{ parcela.motivo_descartado }}",
              name: "motivo_descartado",
            },
            { id: 79, ref: "{{ parcela.metadados }}", name: "metadados" },
            {
              id: 80,
              ref: "{{ parcela.nao_notificar_ate }}",
              name: "nao_notificar_ate",
            },
            { id: 1, ref: "{{ parcela.link_boleto }}", name: "link_boleto" },
          ],
        },
        {
          id: 300,
          name: "Cliente",
          children: [
            { id: 16, ref: "{{ cliente.nome }}", name: "nome" },
            { id: 17, ref: "{{ cliente.documento }}", name: "documento" },
            { id: 18, ref: "{{ cliente.ref_externa }}", name: "ref_externa" },
            {
              id: 19,
              ref: "{{ cliente.data_nascimento }}",
              name: "data_nascimento",
            },
            { id: 26, ref: "{{ cliente.genero }}", name: "genero" },
            { id: 27, ref: "{{ cliente.crop }}", name: "crop" },
            {
              id: 28,
              ref: "{{ cliente.perfil_cliente }}",
              name: "perfil_cliente",
            },
            { id: 29, ref: "{{ cliente.metadados }}", name: "metadados" },
            { id: 30, ref: "{{ cliente.amostra }}", name: "amostra" },
            {
              id: 31,
              name: "Contato",
              children: [
                { id: 112, ref: "{{ cliente.contato.tipo }}", name: "tipo" },
                {
                  id: 113,
                  ref: "{{ cliente.contato.contato }}",
                  name: "contato",
                },
                {
                  id: 114,
                  ref: "{{ cliente.contato.blacklist }}",
                  name: "blacklist",
                },
              ],
            },
            {
              id: 20,
              name: "Endereço",
              children: [
                { id: 23, ref: "{{ cliente.endereco.rua }}", name: "rua" },
                {
                  id: 22,
                  ref: "{{ cliente.endereco.numero }}",
                  name: "numero",
                },
                {
                  id: 21,
                  ref: "{{ cliente.endereco.bairro }}",
                  name: "bairro",
                },
                { id: 25, ref: "{{ cliente.endereco.cep }}", name: "cep" },
                { id: 24, ref: "{{ cliente.endereco.uf }}", name: "uf" },
                {
                  id: 118,
                  ref: "{{ cliente.endereco.cidade }}",
                  name: "cidade",
                },
              ],
            },
          ],
        },
        {
          id: 400,
          name: "Empresa",
          children: [
            { id: 105, ref: "{{ empresa.nome }}", name: "nome" },
            { id: 106, ref: "{{ empresa.nome_curto }}", name: "nome_curto" },
            { id: 107, ref: "{{ empresa.chave }}", name: "chave" },
            {
              id: 108,
              ref: "{{ empresa.razao_social }}",
              name: "razao_social",
            },
            { id: 109, ref: "{{ empresa.cnpj }}", name: "cnpj" },
            { id: 110, ref: "{{ empresa.endereco }}", name: "endereco" },
          ],
        },
        {
          id: 500,
          name: "Pagamento",
          children: [
            {
              id: 81,
              ref: "{{ pagamento.metodo_pagamento }}",
              name: "metodo_pagamento",
            },
            { id: 82, ref: "{{ pagamento.valor }}", name: "valor" },
            { id: 83, ref: "{{ pagamento.pago_em }}", name: "pago_em" },
            {
              id: 84,
              ref: "{{ pagamento.registrado_em }}",
              name: "registrado_em",
            },
          ],
        },
        {
          id: 600,
          name: "Evento",
          children: [
            { id: 85, ref: "{{ evento.dia }}", name: "dia" },
            { id: 86, ref: "{{ evento.nome }}", name: "nome" },
            { id: 87, ref: "{{ evento.tipo }}", name: "tipo" },
            { id: 88, ref: "{{ evento.regua }}", name: "regua" },
            { id: 89, ref: "{{ evento.tipo_regua }}", name: "tipo_regua" },
          ],
        },
        {
          id: 700,
          name: "NPS",
          children: [
            { id: 91, ref: "{{ nps.link_nps }}", name: "link_nps" },
            { id: 92, ref: "{{ nps.pergunta }}", name: "pergunta" },
          ],
        },
        {
          id: 800,
          name: "Movimentação",
          children: [
            {
              id: 93,
              ref: "{{ movimentacao.ref_externa }}",
              name: "ref_externa",
            },
            {
              id: 94,
              ref: "{{ movimentacao.data_referencia }}",
              name: "data_referencia",
            },
            { id: 95, ref: "{{ movimentacao.criado_em }}", name: "criado_em" },
            { id: 96, ref: "{{ movimentacao.metadados }}", name: "metadados" },
            { id: 97, ref: "{{ movimentacao.chave }}", name: "chave" },
          ],
        },
      ],
      contextoExtra: [
        { id: 101, ref: "{{ data_hoje }}", nome: "data_hoje" },
        {
          id: 103,
          ref: "{{ link_descadastrar_email }}",
          nome: "link_descadastrar_email",
        },
        { id: 104, ref: "{{ link_area_logada }}", nome: "link_area_logada" },
        { id: 105, ref: "{{ link_boleto }}", nome: "link_boleto" },
        { id: 106, ref: "{{ link_nps }}", nome: "link_nps" },
      ],
      filtros: [
        {
          nome: "capitalize",
          descricao:
            "Formata um texto, deixando a primeira letra maiúscula e demais minúsculas",
        },
        {
          nome: "date",
          descricao: 'Formata uma data para o formato "dd/mm/aaaa"',
        },
        {
          nome: "date_hour",
          descricao:
            'Formata uma data com horário para o formato "dd/mm/aaaa HH:MM"',
        },
        {
          nome: "day",
          descricao: 'Retorna o número do dia de uma data no formato "dd"',
        },
        {
          nome: 'default("Insira o valor default aqui")',
          descricao:
            "Se o valor da variável não estiver disponível, substitui pelo valor default informado dentro dos parênteses",
        },
        {
          nome: "first_word",
          descricao: "Retorna a primeira palavra de um texto",
        },
        {
          nome: "int",
          descricao:
            "Converte um valor para um número inteiro. Se não for possível converter, retorna 0",
        },
        {
          nome: "last_word",
          descricao: "Retorna a última palavra de um texto",
        },
        {
          nome: "lower",
          descricao: "Formata um texto, deixando todas as letras minúsculas",
        },
        {
          nome: "length",
          descricao: "Retorna a quantidade de items de uma lista",
        },
        {
          nome: "currency",
          descricao:
            "Formata um valor numérico para o formato monetário brasileiro (R$ #.###,##)",
        },
        {
          nome: "month",
          descricao: 'Retorna o número do mês de uma data no formato "mm"',
        },
        { 
          nome: "month_name", 
          descricao: "Retorna o nome do mês de uma data" },
        {
          nome: "numero",
          descricao: 'Formata um valor numérico para o formato "#.###,##"',
        },
        {
          nome: "short_date",
          descricao: 'Formata uma data para o formato "dd/mm"',
        },
        {
          nome: "time",
          descricao: 'Formata um horário para o formato "HH:MM"',
        },
        {
          nome: "time_hour",
          descricao: 'Retorna as horas de um horário no formato "HH"',
        },
        {
          nome: "time_minute",
          descricao: 'Retorna os minutos de um horário no formato "MM"',
        },
        {
          nome: "title",
          descricao:
            "Formata um texto, deixando a primeira letra de cada palavra maiúscula e demais minúsculas",
        },
        {
          nome: "uppercase",
          descricao: "Formata um texto, deixando todas as letras maiúsculas",
        },
        {
          nome: "year",
          descricao: 'Retorna o número do ano de uma data no formato "aa"',
        },
        {
          nome: "formata_linha_digitavel",
          descricao: 'Retorna a linha digitável de um boleto formatada',
        },
      ],
      regras: {
        exigido: (value) =>
          (!!value && value?.replace(/\s/g, "") !== "") ||
          "Este campo não pode ficar vazio",
        numero: (value) =>
          !value || value > 0 || "O tamanho deve ser maior que zero",
      },
    };
  },
  watch: {
    selecionado(novoValor) {
      if (novoValor[0]) {
        this.variavel.referencia += `${novoValor[0]}`;
        this.selecionado = [];
      }
    },
    "variavel.nome"() {
      if (this.variavel.nome) {
        this.variavel.nome = this.variavel.nome.replace(" ", "_").toLowerCase();
      }
    },
    fecharDialog(novoValor) {
      if (novoValor) {
        this.fechar();
      }
    },
  },
  computed: {
    ...mapGetters("variavelRegua", ["fecharDialog"]),
  },
  methods: {
    async abrir(opcoes) {
      this.operacao = opcoes.operacao;
      delete opcoes.operacao;

      if (opcoes) {
        await Object.entries(opcoes).forEach((entry) => {
          const [key, value] = entry;
          this.variavel[key] = value;
        });
      }

      this.dialog = true;
    },
    fechar() {
      this.dialog = false;
      this.mostrarContexto = false;
      this.$refs.form.reset();
      this.variavel.ativa = true;
      this.variavel.referencia = "";
    },
    salvar() {
      if (this.$refs.form.validate()) {
        this.$emit(this.operacao, this.variavel);
        if (this.fecharDialog) {
          this.fechar();
        }
      }
    },
    adicionarFiltro(filtro) {
      let campoReferencia = this.$refs.campoReferencia.$refs.input;
      let texto = campoReferencia.value;
      let tamanho = texto.length;
      let posicao = campoReferencia.selectionStart;
      if (posicao === undefined) {
        posicao = 0;
      }

      let antes = texto.substr(0, posicao);
      let depois = texto.substr(posicao, tamanho);
      let fechamentoContexto = "";

      if (antes.slice(-1) == "}" && depois.slice(0, 1) == "}") {
        fechamentoContexto = antes.lastIndexOf("}");
        antes =
          antes.slice(0, fechamentoContexto) +
          `| ${filtro} ` +
          antes.slice(fechamentoContexto);
      } else if (depois.indexOf("}}") >= 0) {
        fechamentoContexto = depois.indexOf("}}");
        depois =
          depois.slice(0, fechamentoContexto) +
          `| ${filtro} ` +
          depois.slice(fechamentoContexto);
      } else {
        fechamentoContexto = antes.lastIndexOf("}}");
        antes =
          antes.slice(0, fechamentoContexto) +
          `| ${filtro} ` +
          antes.slice(fechamentoContexto);
      }

      this.variavel.referencia = antes + depois;
      this.$nextTick().then(() => {
        campoReferencia.selectionStart = posicao + filtro.length;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.form-variavel {
  .text-black {
    color: black;
  }

  .v-card {
    overflow: hidden;
  }

  .contexto-form {
    padding: 16px 16px 0px 0px !important;

    .pointer {
      cursor: pointer;
      padding: 8px 4px !important;

      &:hover {
        background-color: rgb(0 0 0 / 4%);
      }
    }

    .extra-pointer {
      padding-left: 24px !important;
    }

    .v-expansion-panel-header {
      min-height: 40px;
      font-weight: 600;
      text-transform: uppercase;
      padding: 8px 16px;

      &:hover {
        background-color: rgb(0 0 0 / 4%);
      }
    }

    .v-expansion-panel-content {
      max-height: 472px;
      overflow: auto;

      .v-expansion-panel-content__wrap {
        padding: 0 14px 16px;
      }

      &::-webkit-scrollbar {
        width: 4px;
        margin-left: 2px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #bdbdbd;
        border-radius: 25px;
        padding-right: 0px;
      }
    }

    .v-treeview-node__root {
      padding-left: 0px !important;
      min-height: 38px;
    }

    .v-treeview-node__content {
      margin: 0px !important;
    }

    v-treeview-node__level {
      width: 8px;
    }
  }
}
</style>
