<template>
  <v-dialog v-model="dialog" :width="options.width">
    <v-card>
      <v-card-title
        class="text-h5 primary white--text lighten-2"
        v-if="options.cardTitle"
      >
        {{ options.cardTitle }}
      </v-card-title>

      <v-card-text class="pa-4">
        <span class="text-subtitle-1">
          {{ options.cardText }}
        </span>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="decision = false">
          {{ options.declineBtn }}
        </v-btn>
        <v-btn color="primary" text @click="decision = true">
          {{ options.acceptBtn }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    options: {
      cardText: {
        type: String,
        default: "",
      },
      cardTitle: {
        type: String,
        default: "",
      },
      acceptBtn: {
        type: String,
        default: "ACEITAR",
      },
      declineBtn: {
        type: String,
        default: "CANCELAR",
      },
      width: {
        type: [String, Number],
        default: "300",
      },
    },
  },
  data() {
    return {
      decision: null,
      dialog: false,
    };
  },

  methods: {
    openDialog() {
      this.dialog = true;
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.decision === true || this.decision === false) {
            resolve(this.decision);
            clearInterval(interval);
            this.dialog = false;
            this.decision = null;
          }
        });
      });
    },
  },
};
</script>

<style></style>
