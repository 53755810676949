<template>
  <v-expansion-panel-header
    class="pa-1 d-flex justify-start"
    :style="!verificaEventoAtivo(regua) && !regua.id_regua_desafiante && 'cursor: unset'"
  >
    <!-- caso não tenha eventos ou regua desafiante, deixa o expansion panel sem o icone -->
    <template v-slot:actions>
      <v-icon
        :class="!verificaEventoAtivo(regua) && !regua.id_regua_desafiante && 'mr-6'"
        color="primary"
        v-if="!verificaEventoAtivo(regua) && !regua.id_regua_desafiante"
      >
      </v-icon>
    </template>

    <div class="d-flex align-center">
      <v-switch
        v-model="regua.status"
        value="PRODUCAO"
        class="mr-2"
        v-if="checkCredentials(['admin', 'editor_regua'])"
        :disabled="regua.status == 'ARQUIVADA'"
        @click.stop.native.capture="
          regua.status === 'PRODUCAO' ? handlePararRegua(regua) : handleRodarRegua(regua)
        "
      ></v-switch>

      <p class="text-subtitle-1 regua__name mb-0 mr-2" :class="regua.status">
        {{ regua.nome }}
        <v-icon class="ml-1" v-if="regua.id_regua_desafiante"> mdi-alpha-a-circle-outline </v-icon>
      </p>

      <v-tooltip bottom v-if="regua.id_regua_desafiante">
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-bind="attrs" v-on="on" color="orange" small class="ml-2" dark>
            Teste A/B
          </v-chip>
        </template>

        <span>
          Amostra início:
          {{ regua.digito_verificador_inicio }}
        </span>
        <br />
        <span>
          Amostra fim:
          {{ regua.digito_verificador_fim }}
        </span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="ml-1" v-show="regua.observacao" v-on="on" small>mdi-text-long</v-icon>
        </template>
        <span>{{ regua.observacao }}</span>
      </v-tooltip>

      <v-chip
        small
        outlined
        link
        @click.stop="handleVisualizarPerfisRegua(regua)"
        v-if="regua.perfis?.length > 0 && regua.status != 'ARQUIVADA'"
        :color="regua.status != 'PRODUCAO' ? 'grey' : 'purple'"
        class="mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none ml-2"
      >
        <span class="text-subtitle-2 font-weight-black">
          {{ regua.perfis?.length }}
          {{ regua.perfis?.length > 1 ? "perfis vinculados" : "perfil vinculado" }}
        </span>
      </v-chip>

      <v-spacer></v-spacer>

      <div
        v-if="regua.eventos_info"
        class="shrink flex-row align-center ml-2"
        :class="{
          'indigo--text text--accent-3': regua.status == 'PRODUCAO',
        }"
      >
        <div
          class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
          v-if="regua.eventos_info.quantidade_eventos > 0"
          @click.stop="handleRedirectEvento(regua.id)"
        >
          <v-chip
            outlined
            link
            small
            :color="regua.status !== 'PRODUCAO' ? 'grey' : 'indigo accent-3'"
            class="mr-2 d-flex flex-column white--text regua__evento__text"
          >
            <span class="text-subtitle-2 font-weight-black">
              {{ regua.eventos_info.quantidade_eventos }}
              {{ regua.eventos_info.quantidade_eventos > 1 ? "eventos" : "evento" }}
              <span
                v-if="regua.tipo != 'IMPLANTACAO' && regua.eventos_info.janela_eventos > 1"
                class="text-secondary text-caption font-weight-light events__sub"
              >
                em
                {{ regua.eventos_info.janela_eventos }}
                {{ regua.eventos_info.janela_eventos > 1 ? "dias" : "dia" }}
              </span>
            </span>
          </v-chip>
        </div>
      </div>

      <div
        class="align-center regua__wrapper__menu ml-2"
        v-if="checkCredentials(['admin', 'editor_regua'])"
      >
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="handleEditarRegua(regua)">
              <v-list-item-title>Editar Informações</v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleRedirectEvento(regua.id)">
              <v-list-item-title> Editar Eventos </v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleDashboardRegua(regua.id)">
              <v-list-item-title> Dashboard </v-list-item-title>
            </v-list-item>
            <v-list-item @click="handleToggleRegua(regua)">
              <v-list-item-title>
                {{ regua.status != "ARQUIVADA" ? "Arquivar" : "Ativar" }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </v-expansion-panel-header>
</template>
<script>
import { checkCredentials } from "@/assets/js/utils";

export default {
  props: {
    regua: {
      required: true,
      default: () => {},
    },
  },
  methods: {
    checkCredentials,
    verificaEventoAtivo(regua) {
      return regua.eventos.some((ev) => ev.status === "PRODUCAO");
    },
    handleVisualizarPerfisRegua(regua) {
      this.$emit("visualizarPerfisRegua", regua);
    },
    handleRedirectEvento(reguaId) {
      this.$emit("redirectEvento", reguaId);
    },
    handleDashboardRegua(reguaId) {
      this.$emit("redirectDashboard", reguaId);
    },
    handleEditarRegua(regua) {
      this.$emit("editarRegua", regua);
    },
    handleToggleRegua(regua) {
      this.$emit("toggleRegua", regua);
    },
    handleRodarRegua(regua) {
      this.$emit("rodarRegua", regua);
    },
    handlePararRegua(regua) {
      this.$emit("pararRegua", regua);
    },
  },
};
</script>
