import http from "@/plugins/axios";
import store from "@/store";

const VERSION = "/v1";

export default {
  blacklist(payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.post(
      empresa.url_instancia + VERSION + "/blacklist",
      payload
    );
  },

  removerBlacklist(id) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.delete(
      empresa.url_instancia + VERSION + `/blacklist/${id}`
    );
  },
};
